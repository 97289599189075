.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
    background-color: #1E1E1E;
}
.section1{
  width: 100%;
  height: 100vh;
  background-color: #1E1E1E;
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}
.section1_1{
  width: 100%;
  height: 300vh;
  background-color: #1E1E1E;
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: flex-start;
}
.section1_1_1{
  width: 100%;
  height: 100vh;
  background-color: #1E1E1E;
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: row;
}
.section1-text-holder{
  width: 35vw;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.section1-image-holder{
  width: 65vw;
  height: 100vh;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.section1_image{
  width: 60vw;
  height: 45vw;
}
.section1_text{
  width: 30vw;
  font-size: 35px;
  color: white;
  text-align: left;
}
.section3{
  width: 100%;
  height: 50vh;
  background-color: #1E1E1E;
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  padding-bottom: 200px;
}
.logo{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.logo1{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.button{
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  background-color: #FC6AFF;
  width: 300px;
  height: 75px;
  color: white;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  text-decoration: none;
}
.section1_half{
  width: 50%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
}
.section2_half{
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
}
.section1_half_box{
  width: 100%;
  height: 20vh;
 
  margin: 0;
  padding: 0;
  position: relative;
}
.section2_half_box{
  width: 100%;
  height: 40vh;
  
  margin: 0;
  padding: 0;
  position: relative;
}
.text_box{
  width: 50%;
  height: 10vh;
  background-color: #6D6AFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px 26px 0px 26px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.text_box2{
  width: 90%;
  height: 34vh;
  background-color: #6D6AFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px 26px 0px 26px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.text_box_text{
  color: white;
  font-size: 14px;
  width: 80%
}
.text_box_text1{
  color: white;
  font-size: 1.5vw;
  width: 80%;
  
}
.text_box1{
  width: 55%;
  height: 10vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px 26px 0px 26px;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
 
}
.logo_text{
  font-size: 50px;
  color: white;
}
#phone{
  display: none;
}
#comp{
  display: block;
}
.input{
  width: 300px;
 
  font-size: 15px;
  padding-left: 25px;
  border-radius: 15px;
  height: 100%;
  outline: none;
  background-color: transparent;
  border: 1px solid #6D6AFF;
  color: white;
}
.wrap{
  display: flex;
  width: 500px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.sub{
  background-color: #6D6AFF;
  color: white;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  height: 55px;
  border-radius: 10px;

  margin-left: 25px;
}

@media (pointer:none), (pointer:coarse), (max-width: 600px) {
#boxes{
  display: none;
}
.text_box_text1{
  background-color: #6D6AFF;
  color: white;
  font-size: 12px;
  width: 90%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.text_box2{
  width: 90%;
  height: 40vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px 26px 0px 26px;

}
.logo{
  text-wrap: nowrap;
  font-size: 20px;
}
.logo_text{
  font-size: 25px;
}
.section1_1_1{
  width: 100%;
  height: 100vh;
  background-color: #1E1E1E;
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
}
.section1-text-holder{
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section1-image-holder{
  width: 100vw;
  height: 100vh;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.section1_image{
  width: 90vw;
  height: 71vw;
}
.section1_text{
  width: 90vw;
  font-size: 35px;
  color: white;
  text-align: center;
}
#phone{
  display: block;
}
#comp{
  display: none;
}
.logo1{
  width: 90vw;
}
.input{
  width: 90vw;
  background-color: transparent;
  font-size: 25px;
  padding: 0px;
  border-radius: 15px;
  height: 50%;
  outline: none;
  text-align: center;
  border: 1px solid #6D6AFF;
  color: white;
}
.wrap{
  display: flex;
  width: 90vw;
  height: 200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sub{
  background-color: #6D6AFF;
  color: white;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 50%;
  border-radius: 10px;
  margin-left: 0px;
}

}
.lynxemail{
  position: absolute;
  bottom: 15px;
  color: white;
  font-size: 17px;
}

.grad_test{
  width: 100%;
  height: 400px;
  background: linear-gradient(to bottom, #1E1E1E, #6D6AFF);
}
.grad_test1{
  width: 100%;
  height: 400px;
  background: linear-gradient(to bottom, #6D6AFF,#1E1E1E);
}
.section2{
  width: 100%;
  height: 30vh;
  background-color: #6D6AFF;
  position: relative;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5);
}
80% {
  transform: scale(1.2);
}
100% {
    opacity: 1;
    transform: scale(1);
}
}

.find{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
}


.sub:hover{
  cursor: pointer;
}